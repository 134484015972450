// extracted by mini-css-extract-plugin
export var gallery = "hero-gallery-module--gallery--aTeel";
export var galleryLoadingOverlay = "hero-gallery-module--galleryLoadingOverlay--LeLL1";
export var galleryLoadingOverlayReady = "hero-gallery-module--galleryLoadingOverlayReady--BYP1y";
export var galleryWrapper = "hero-gallery-module--galleryWrapper--0iToo";
export var galleryOverlay = "hero-gallery-module--galleryOverlay--VGa-O";
export var galleryOverlayLink = "hero-gallery-module--galleryOverlayLink--JDEzy";
export var galleryOverlayColumn = "hero-gallery-module--galleryOverlayColumn--tzsB3";
export var galleryOverlayColumnLink = "hero-gallery-module--galleryOverlayColumnLink--Q-SVE";
export var galleryButton = "hero-gallery-module--galleryButton--tcN-q";
export var galleryButtonRight = "hero-gallery-module--galleryButtonRight--94DK5";
export var galleryEN = "hero-gallery-module--galleryEN--FB4xk";
export var galleryLogo = "hero-gallery-module--galleryLogo--FP-FU";
export var gallerySlide = "hero-gallery-module--gallerySlide--rK+kd";
export var gallerySlideContent = "hero-gallery-module--gallerySlideContent--2MU9N";
export var gallerySlideContentLink = "hero-gallery-module--gallerySlideContentLink--Ptp5x";
export var gallerySlideContainer = "hero-gallery-module--gallerySlideContainer--Zgpc6";
export var gallerySlideImage = "hero-gallery-module--gallerySlideImage--z2u0-";