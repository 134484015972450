import React from 'react';
import clsx from 'clsx';
import { TDetails } from 'keen-slider';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'components/image';
import Link, { LinkType } from 'components/link';
import Column, { ColumnResponsiveSizes } from 'components/column';
import GalleryPagination from 'components/gallery-pagination';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Logo from 'images/svg/logo.inline.svg';

import * as styles from './hero-gallery.module.scss';

type HeroGalleryProps = {
  heroProjects: GatsbyTypes.DatoCmsProject[];
  height?: string;
};

type HeroGalleryItemProps = {
  getContentStyle: (index: number) => React.CSSProperties;
  getImageStyle: (index: number) => React.CSSProperties;
  project: GatsbyTypes.DatoCmsProject;
  index: number;
  setReady: (ready: boolean) => void;
};

const HeroGalleryItem: React.FC<HeroGalleryItemProps> = ({
  project,
  getContentStyle,
  getImageStyle,
  index,
  setReady,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('gallery-slide', styles.gallerySlide)}>
      <div className={styles.gallerySlideContent} style={getContentStyle(index)}>
        <h4>{project.title}</h4>
        <h4>
          <Link link={project as LinkType} arrow={'right'}>
            {t('Läs mer')}
          </Link>
        </h4>
      </div>
      <div className={styles.gallerySlideContainer} style={getImageStyle(index)}>
        {React.useMemo(
          () => (
            <Image
              className={styles.gallerySlideImage}
              onLoad={() => setReady(true)}
              {...project?.featuredImage}
            />
          ),
          [project?.featuredImage, setReady]
        )}
      </div>
    </div>
  );
};

const overlayColConfig: ColumnResponsiveSizes = {
  xl: 9,
  lg: 9,
  md: 9,
};

const HeroGallery: React.FC<HeroGalleryProps> = ({ heroProjects, height, children }) => {
  const { t, i18n } = useTranslation();

  const [pause, setPause] = React.useState<boolean>(false);
  const [ready, setReady] = React.useState<boolean>(false);
  const timer = React.useRef<NodeJS.Timeout>();
  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [details, setDetails] = React.useState<TDetails>();
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    duration: 1800,
    slideChanged: (s) => setCurrentSlide(s.details().relativeSlide),
    move: (s) => setDetails(s.details()),
    slides: '.gallery-slide',
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  const scrollNext = React.useCallback(() => {
    slider.next();
  }, [slider]);
  const scrollPrev = React.useCallback(() => {
    slider.prev();
  }, [slider]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && scrollNext && ready) {
        scrollNext();
      }
    }, 6000);
    return () => timer.current && clearInterval(timer.current);
  }, [pause, ready, scrollNext]);

  const getImageStyle = React.useCallback(
    (index: number): React.CSSProperties => {
      if (!details) {
        return {};
      }
      const position = details.positions[index];
      const x = details.widthOrHeight * position.distance * -0.92;

      return {
        transform: `translate3d(${x}px, 0px, 0px)`,
        WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
      };
    },
    [details]
  );
  const getContentStyle = React.useCallback(
    (index: number): React.CSSProperties => {
      if (!details) {
        return {};
      }
      const position = details.positions[index];
      const x = details.widthOrHeight * position.distance * -1.02;

      return {
        transform: `translate3d(${x}px, 0px, 0px)`,
        WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
        opacity: position.portion,
      };
    },
    [details]
  );

  return (
    <section className={styles.galleryWrapper}>
      <div
        className={clsx(styles.galleryLoadingOverlay, {
          [styles.galleryLoadingOverlayReady]: ready,
        })}
      />
      <div
        ref={sliderRef}
        style={{ height }}
        className={clsx(styles.gallery, (styles as never)[`gallery${i18n.language.toUpperCase()}`])}
      >
        <button
          aria-label={'Previous slide'}
          className={styles.galleryButton}
          onClick={scrollPrev}
        />
        <button
          aria-label={'Next slide'}
          className={clsx(styles.galleryButton, styles.galleryButtonRight)}
          onClick={scrollNext}
        />
        {heroProjects.map((project, index) => (
          <HeroGalleryItem
            key={project.id}
            project={project}
            setReady={setReady}
            getContentStyle={getContentStyle}
            getImageStyle={getImageStyle}
            index={index}
          />
        ))}
      </div>
      <Column className={styles.galleryOverlay}>
        <h4 className={styles.galleryOverlayLink}>
          <Link
            link={{
              model: { apiKey: 'project_archive' },
            }}
            arrow={'right'}
          >
            {t('Se alla våra uppdrag')}
          </Link>
        </h4>
        <Logo className={styles.galleryLogo} />
        <Column className={styles.galleryOverlayColumn} {...overlayColConfig}>
          <h4>
            <Link
              className={styles.galleryOverlayColumnLink}
              link={{
                model: { apiKey: 'project_archive' },
              }}
              arrow={'right'}
            >
              {t('Se alla våra uppdrag')}
            </Link>
          </h4>
          <GalleryPagination
            currentSlide={currentSlide + 1}
            totalSlides={heroProjects.length}
            size={'large'}
          />
        </Column>
      </Column>
      {children}
    </section>
  );
};

export default HeroGallery;
