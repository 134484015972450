import React from 'react';
import clsx from 'clsx';
import HeroGallery from 'sections/hero-gallery';
import Link, { LinkType } from 'components/link';
import Column from 'components/column';
import Section, { SectionColor } from 'components/section';

import * as styles from './start-hero.module.scss';
import MatrixFigure from 'components/matrix-figure';
import LayoutContext from 'layouts/reducer';

export type StartHeroProps = Pick<
  GatsbyTypes.DatoCmsStartPage,
  'titleBackgroundColor' | 'titleLink' | 'titleText' | 'heroProjects'
>;

const StartHero: React.FC<StartHeroProps> = ({
  heroProjects,
  titleText,
  titleLink,
  titleBackgroundColor,
}) => {
  const { state } = React.useContext(LayoutContext);
  const ref = React.createRef<HTMLElement>();
  const [galleryHeight, setGalleryHeight] = React.useState<string>();

  React.useEffect(() => {
    if (!ref.current) return;

    const handleResize = () =>
      ref.current && setGalleryHeight(`calc(100vh - ${ref.current.clientHeight}px)`);
    handleResize();

    if (state.isSmall) {
      window.addEventListener('resize', handleResize);
    } else {
      window.removeEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [ref, state.isSmall]);

  return (
    <>
      <HeroGallery
        heroProjects={heroProjects as GatsbyTypes.DatoCmsProject[]}
        height={galleryHeight}
      >
        <MatrixFigure className={styles.heroFigure} size={'large'} />
      </HeroGallery>
      <Section
        ref={ref}
        className={clsx(
          styles.titleComponent,
          (styles as never)[`titleComponentBG${titleBackgroundColor}`]
        )}
        backgroundColor={titleBackgroundColor as SectionColor}
      >
        <Column className={styles.titleComponentColumn} xl={7} lg={7} md={6} sm={8}>
          <h2 className={styles.titleComponentText}>{titleText}</h2>
        </Column>
        <Column className={styles.titleComponentColumn} xl={2} lg={2} md={3} sm={4}>
          <h3 className={styles.titleComponentLink}>
            {titleLink && (
              <Link
                link={titleLink[0]?.link as LinkType}
                externalUrl={titleLink[0]?.externalUrl}
                arrow={'right'}
              >
                {titleLink[0]?.text}
              </Link>
            )}
          </h3>
        </Column>
      </Section>
    </>
  );
};

export default StartHero;
