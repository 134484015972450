// extracted by mini-css-extract-plugin
export var titleComponent = "start-hero-module--titleComponent--udp35";
export var titleComponentBGlight = "start-hero-module--titleComponentBGlight--2KxOR";
export var titleComponentLink = "start-hero-module--titleComponentLink--g8Qqx";
export var titleComponentBGmint = "start-hero-module--titleComponentBGmint--e5Tv3";
export var titleComponentText = "start-hero-module--titleComponentText--R6Xqx";
export var titleComponentBGblue = "start-hero-module--titleComponentBGblue--0vbDZ";
export var titleComponentBGneon = "start-hero-module--titleComponentBGneon--zz-Bp";
export var titleComponentBGconcrete = "start-hero-module--titleComponentBGconcrete--Phzkg";
export var titleComponentColumn = "start-hero-module--titleComponentColumn--BEXAZ";
export var heroFigure = "start-hero-module--heroFigure--mtfkT";