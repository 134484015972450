import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import StartHero from 'sections/start-hero';
import RenderBlocks, { BlockType } from 'utils/RenderBlocks';
import LangSlug from 'components/lang-slug';

type IndexPageProps = {
  data: {
    startPage: GatsbyTypes.DatoCmsStartPage;
  };
};

const IndexPage: React.FC<IndexPageProps> = ({ data: { startPage } }) => {
  return (
    <>
      <LangSlug model={'start_page'} />
      <HelmetDatoCms seo={startPage.seoMetaTags} />
      <StartHero {...startPage} />
      <RenderBlocks data={startPage.body as BlockType[]} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query StartPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    startPage: datoCmsStartPage {
      seoMetaTags {
        tags
      }
      heroProjects {
        id: originalId
        title
        slug
        model {
          apiKey
        }
        featuredImage {
          ...Image
        }
      }
      titleBackgroundColor
      titleText
      titleLink {
        ...Link
      }
      body {
        ...Spacing
        ...ImageAndTextCta
        ...LargeLinkCta
        ...NewsFeed
        ...LargeAccordion
      }
    }
  }
`;
